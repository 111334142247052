.pdf {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
}

th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}
.logo {
  text-align: center;
  margin-bottom: 20px;
}

.logo img {
  max-width: 200px;
}

.custom-td {
  border-bottom: none;
  font-weight: bold;
}
