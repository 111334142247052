.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #000;
  border-radius: 50%;
  margin: 0 5px;
  animation-name: move;
  animation-duration: 0.9s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.dot.dot-1 {
  animation-delay: 0.2s;
}

.dot.dot-2 {
  animation-delay: 0.4s;
}

.dot.dot-3 {
  animation-delay: 0.6s;
}

@keyframes move {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
